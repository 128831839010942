
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



























































::v-deep {
    .dropdown-content {
        .dropdown-item {
            padding: 0 !important;

            .media {
                align-items: center;
            }

            & > .button {
                width: 100%;
                color: black;
                display: flex;
                border-radius: 0;
                justify-content: left;

                &:not(:hover) {
                    background-color: white;
                }

                &:hover {
                    font-weight: bolder;

                    .button {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
