
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





































































































.media {
    &-content {
        font-size: 1em;
    }
}

.is-light {
    .media {
        &-content {
            color: black !important;
        }
    }
}

.custom-icon {
    margin-left: -14.5px;
    margin-right: -14.5px;
    width: $size-5;
    height: $size-5;
}
