
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        







































































































































































































































































































































































































































































































































































































.line-through {
    position: relative;

    &:after {
        content: "";
        top: 0;
        left: 0;
        width: 4px;
        height: 120%;
        position: absolute;
        transform: translate(5px, -2px) rotateZ(-45deg);
        background-image: linear-gradient(90deg, #B5B5B5 50%, white 50%);
    }
}

.is-desc {
    transform: rotate(180deg);
}

.arrow-icon {
    transition: transform .15s;
}

.is-invisible-arrow {
    visibility: hidden;
}
