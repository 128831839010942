
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

















































































































::v-deep {
    .animation-content {
        width: 100%;
        max-width: 550px !important;

        .modal-card {
            width: 100%;
            max-width: none;

            &-head {
                padding-bottom: 15px;
            }

            &-foot {
                padding-top: 15px;
            }
        }
    }
}
