
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
































































































































































.b-skeleton {
  width: initial;
}

.empty {
    line-height: 1.2;
}

::v-deep {
    .table {
        th {
            &:first-child {
                border-radius: 5px 0 0 0;
            }

            &:last-child {
                border-radius: 0 5px 0 0;
            }
        }
    }
}
