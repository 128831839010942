
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

























































































































































































































































@mixin table-shadow($color, $size) {
    & {
        position: relative;
    }

    &.is-shadow {
        &:not(:last-of-type):before {
            content: "";
            top: -1px;
            z-index: 10;
            right: 0;
            width: $size;
            position: absolute;
            pointer-events: none;
            height: calc(100% + 1px);
            box-shadow: inset (-$size) 0 $size (-$size) $color;
        }
    }
}

::v-deep {
    .sort-icon {
        line-height: 1.5;
    }

    .v-popover {
        & + span {
            .sort-icon {
                line-height: 2.4;
            }
        }
    }

    table {
        thead, tbody {
            background-color: white;
        }

        thead {
            tr {
                &:first-child {
                    th {
                        padding: 0;

                        > div {
                            display: flex;
                            padding: 20px;
                            align-items: center;
                            justify-content: space-between;

                            label {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    .b-table {
        $color: rgba(0, 0, 0, 0.1);
        $size: 20px;

        thead {
            top: 0;

            tr {
                th {
                    @include table-shadow($color, $size);
                }
            }
        }

        tbody, tfoot {
            tr {
                td {
                    @include table-shadow($color, $size);
                }
            }
        }

        tfoot {
            tr {
                td {
                    z-index: 20;
                }
            }
        }
    }
}

.card {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.icon-btn {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
}
